import { h } from "preact";
import { useMemo } from "preact/hooks";
import { useTranslation } from "react-i18next";
import { BaseRetention, IBaseRetentionProps } from "../base";

import type { CreditToolViewComponent } from "@model/view/credit-tool";
import type { RetentionView } from "../base/types";

type PageRetentionProps = Record<string, IBaseRetentionProps>;

export interface IMSAToolRetentionProps {
    page: keyof PageRetentionProps;
}

export const MSAToolRetention: RetentionView<
    any,
    CreditToolViewComponent
> = (props) => {
    const { t } = useTranslation();

    const retentionProps = useMemo(() => {
        return {
            title: t("msa.retention.score.title"),
            subtitles: [
                t("msa.retention.score.subtitle"),
            ],
            buttonsProps: [
                {
                    label: t("msa.retention.score.button"),
                    onClick: () => null,
                },
            ],
        };
    },[]);

    return (
        <BaseRetention
            {...props}
            {...retentionProps}
        />
    );
};