import { h } from "preact";
import { useTranslation } from "react-i18next";

import { MessagePage } from "@app-modal/Modal/MessagePage";

import { useMSAToolModels } from "@msa-tool/state";
import thumbsup from "@assets/emoji/emoji-thumbs-up.png";

import type { MSAToolViewComponent } from "@msa-tool";
import { getCustomer } from "@msa-tool/config/customers";

const maxRetries = 1;

export const CustomErrorStep: MSAToolViewComponent = ({ goToNext, navigateTo }) => {
    const {
        msaTool: {
            useSelector,
            update,
        },
    } = useMSAToolModels();
    const retries = useSelector("retries");
    const { equifaxErrorMessages } = useSelector().eidQuery();
    const { t } = useTranslation();

    const retry = () => {
        update.retries(retries + 1);
        navigateTo("phone");
    };

    const canRetry = retries < maxRetries;
    const config = getCustomer();

    return (
        <MessagePage
            emoji={thumbsup}
            title={t("msa.clcError.title")}
            messages={[
                t("msa.clcError.message"),
                t("msa.clcError.message2"),
                config?.phoneNumber || "",
                config?.email || "",
            ]}
            bulletHeader={t("msa.clcError.bulletHeader")}
            bulletList={equifaxErrorMessages}
            actions={canRetry ? [
                {
                    onClick: retry,
                    label: (
                        <>
                            {t("msa.clcError.button")}
                        </>
                    ),
                },
            ] : undefined}
        />
    );
};