import { transformFromPhone } from "@util/phone";
import {
    rawPostalCode,
    dateToRaw,
    toFloat,
} from "@util/transforms";

import type { ICombinedState } from "@msa-tool/state";
import type { LeadInput } from "@graphql/schema";
import { isNil, omitBy } from "lodash";

export const mapStateToPartialLead = (state: ICombinedState): LeadInput => {
    const partialLead: LeadInput = {
        id: state.leadId || undefined,
        sourceUrl: state.sourceUrl,
        firstName: state.firstName,
        lastName: state.lastName,
        dateOfBirth: dateToRaw(state.dateOfBirth),
        phoneNumber: transformFromPhone(state.phoneNumber),
        emailAddress: state.emailAddress,
        postalCode: rawPostalCode(state.postalCode),
        streetNumber: state.streetNumber,
        street: state.street,
        unit: state.unit,
        city: state.city,
        provinceCode: state.province,
        country: state.country || "CA",
        desiredVehicle: state.vehicle,
        companyName: state.employer,
        jobTitle: state.position,
        incomeAmount: toFloat(state.incomeAmount),
        incomePeriod: "monthly",
        employmentStatus: state.employmentStatus,
        incomeTimeYears: parseInt(state.incomeTimeYears || "0"),
        incomeTimeMonths: parseInt(state.incomeTimeMonths || "0"),
        residenceTimeYears: parseInt(state.residenceTimeYears || "0"),
        residenceTimeMonths: parseInt(state.residenceTimeMonths || "0"),
        meta: {
            productVariant: "msa",
        },
        phoneVerifiedAt: state.phoneVerifiedAt,
        additionalInformation: {
            ...state.additionalInformation,
            interestRate: state.interestRate,
            monthlyBudget: state.monthlyBudget,
        },
        assignee: state.assignee,
        language: state.language,
    };

    return omitBy(partialLead, isNil) as any as LeadInput;
};

export type MappedPartialPayload = ReturnType<typeof mapStateToPartialLead>;