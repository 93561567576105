import { getStateMapper } from "@util/payload";
import { transformFromPhone } from "@util/phone";
import { rawPostalCode, dateToRaw } from "@util/transforms";

import type { CreditAuthInput } from "@graphql/schema";
import type { ICombinedState } from "@credit-tool/state";

type CreditAuthPayload = Definitely<CreditAuthInput>;

export const mapStateToAuth = getStateMapper<ICombinedState, CreditAuthPayload>()({
    stateKeys: [
        "leadId",
        "firstName",
        "lastName",
        "dateOfBirth",
        "phoneNumber",
        "emailAddress",
        "street",
        "streetNumber",
        "unit",
        "city",
        "postalCode",
        "province",
        "country",

        "sourceUrl",
        "dealerCreditConsent",
        "campaign",
    ],
    keyMap: {
        leadId: "id",
    },
    transformers: {
        phoneNumber: transformFromPhone,
        postalCode: rawPostalCode,
        dateOfBirth: dateToRaw,
    },
    skipFalsy: [
        "leadId",
        "sourceUrl",
        "campaign",
    ],
});

export type MappedAuthPayload = ReturnType<typeof mapStateToAuth>;