import { createRouteMap } from "@model/routes";

export const routeMap = createRouteMap({
    base: "/credit-lite-tool",
    errorPage: "error",
    routes: {
        // "splash": { next: "step-1" },
        "step-1": { next: "postal-score" },
        "postal-score": {},
        "error": { next: "step-1", back: false },
        // "thank-you": {},
    },
});

export type { CreditLiteToolRouteMapping } from "@model/routes";