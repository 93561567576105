import { getStateMapper } from "@util/payload";
import { transformFromPhone } from "@util/phone";
import {
    rawPostalCode,
    dateToRaw,
} from "@util/transforms";

import type { ICombinedState } from "@credit-tool/state";
import type { LeadInput } from "@graphql/schema";

export const mapStateToPartialLead = getStateMapper<ICombinedState, LeadInput>()({
    stateKeys: [
        "leadId",
        "sourceUrl",

        "firstName",
        "lastName",
        "dateOfBirth",
        "phoneNumber",
        "emailAddress",

        "postalCode",
        "streetNumber",
        "street",
        "unit",
        "city",
        "province",
        "country",
        // "campaign",
        "additionalInformation",
        "assignee",
        "smsConsent",
        "language",
    ],
    keyMap: {
        leadId: "id",
        province: "provinceCode",
    },
    transformers: {
        phoneNumber: transformFromPhone,
        postalCode: rawPostalCode,
        dateOfBirth: dateToRaw,
    },
    skipFalsy: [
        "sourceUrl",
        "leadId",
        "campaign",
        "postalCode",
        "streetNumber",
        "street",
        "unit",
        "city",
        "province",
        "country",
    ],
    defaultValues: {
        country: "CA",
        smsConsent: false,
    },
});

export type MappedPartialPayload = ReturnType<typeof mapStateToPartialLead>;