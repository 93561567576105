import { h, FunctionalComponent } from "preact";
import { useTranslation } from "react-i18next";
import { useCreditToolModels } from "@credit-tool/state";
import { Text } from "@components/Text/Text";
import { useProductRouter } from "@hooks/use-product-router";

import styles from "./Header.module.scss";
import CloseIcon from "@assets/close-icon.svg";
import BackIcon from "@assets/back-icon.svg";

export interface IHeaderProps {
    backButton: boolean;
    closeButton: boolean;
    goToPrev: () => void;
    onClose?: () => void;
    // progress?: number;
}

const LeftPanel: FunctionalComponent = ({ children }) => (
    <div className={styles.headerPanelLeft}>{children}</div>
);
const RightPanel: FunctionalComponent = ({ children }) => (
    <div className={styles.headerPanelRight}>{children}</div>
);

export const Header: FunctionalComponent<IHeaderProps> = ({
    children,
    backButton,
    closeButton,
    onClose,
    goToPrev,
}) => {
    const { t, i18n } = useTranslation();
    const {
        products: { state: productsState },
        user: { update: updateUser },
    } = useCreditToolModels();
    const router = useProductRouter();
    const hasFrench = productsState.creditTool || productsState.driveTool || productsState.msaTool;
    // We want to avoid additional API hits to the Backend,
    // so we will hide the language dropdown on the following pages:
    //  - "/confirm"
    //  - "/eid"
    //  - "/score"
    //  - "/score-details"
    const showLanguageDropdown = (
        !router.curRoute.includes("confirm") &&
        !router.curRoute.includes("eid") &&
        !router.curRoute.includes("score") &&
        !router.curRoute.includes("score-details")
    );

    return (
        <div className={styles.header}>
            <LeftPanel>
                {backButton && (
                    <div className={styles.backIconContainer} onClick={goToPrev}>
                        <BackIcon />
                        <Text block className={styles.backIconText}>{t("back")}</Text>
                    </div>
                )}
            </LeftPanel>
            <RightPanel>
                {hasFrench && showLanguageDropdown && (
                    <select
                        value={i18n.language}
                        onChange={(e) => {
                            updateUser.language(e.currentTarget.value);
                            i18n.changeLanguage(e.currentTarget.value);
                        }}
                        className={styles.langDropdown}
                        style={{ marginRight: closeButton ? "16px" : "0" }}
                    >
                        <option value="en">🇨🇦 - English</option>
                        <option value="fr">🇨🇦 - Français</option>
                    </select>
                )}
                {closeButton && (
                    <div className={styles.closeIcon} onClick={onClose}>
                        <CloseIcon />
                    </div>
                )}
            </RightPanel>
            {children}
        </div>
    );
};