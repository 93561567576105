import { h, FunctionalComponent } from "preact";
import { useState, useCallback } from "preact/hooks";
import { useTranslation } from "react-i18next";
import { Button } from "@components/Button";
import FeedbackAvatar from "@assets/feedback-avatar.png";

import styles from "./FeedbackCapture.module.scss";

import type { IModalViewActions } from "@model/view/modal";

interface IFeedbackCaptureProps {
    submitFeedback: IModalViewActions["submitFeedback"];
}

export const FeedbackCapture: FunctionalComponent<IFeedbackCaptureProps> = ({ submitFeedback }) => {
    const { t } = useTranslation();
    const [feedbackInput, setFeedbackInput] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = useCallback(()  => {
        if (isSubmitted) return;
        submitFeedback(feedbackInput);
        setIsSubmitted(true);
    }, [feedbackInput, isSubmitted, submitFeedback]);

    return (
        <div className={styles.feedbackContainer}>
            <div className={styles.feedbackDialog}>
                <label for="feedback-input" className={styles.feedbackInputLabel}>
                    {isSubmitted
                        ? t("feedbackCapture.titleComplete")
                        : t("feedbackCapture.title")
                    }
                </label>
                <textarea
                    id="feedback-input"
                    name="feedback-input"
                    rows={4}
                    cols={100}
                    placeholder={t("feedbackCapture.placeholder")}
                    disabled={isSubmitted}
                    className={styles.feedbackInput}
                    onChange={(e) => {
                        setFeedbackInput(e.currentTarget.value);
                    }}
                >
                    {feedbackInput}
                </textarea>
                <Button
                    className={styles.feedbackSubmit}
                    onClick={handleSubmit}
                    disabled={isSubmitted}
                >
                    {isSubmitted ? t("feedbackCapture.buttonComplete") : t("feedbackCapture.button")}
                </Button>
            </div>
            <div className={styles.feedbackAvatarContainer}>
                <div className={styles.imgContainer}>
                    <img
                        src={FeedbackAvatar}
                        alt="feedback-dialog-avatar-img"
                    />
                </div>
            </div>
        </div>
    );
};