import { h, FunctionalComponent, RefObject } from "preact";
import { useRef, useEffect } from "preact/hooks";
import { useTranslation } from "react-i18next";
import css from "dom-css";

import { Title, ErrorText } from "@components/Text";
import { Button } from "@components/Button";

import { frameHandler } from "@host";

import styles from "./CriticalError.module.scss";
import grimace from "@assets/emoji-grimace.png";

interface ICriticalErrorProps {
    parentContainer?: RefObject<HTMLElement | null>;
    message?: string;
    service?: string;
    close: () => void;
}

export const CriticalError: FunctionalComponent<ICriticalErrorProps> = ({
    message,
    service = "Free Credit Snapshot",
    close,
    parentContainer,
}) => {
    const { t } = useTranslation();
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let origHeight: string | number;
        if (contentRef.current) {
            // console.log(contentRef.current, parentContainer);
            if (parentContainer?.current) {
                origHeight = parentContainer.current.style.minHeight;
                css(parentContainer.current, {
                    minHeight: contentRef.current.clientHeight,
                });
            }
            frameHandler.scrollTo(contentRef.current);
        }

        return () => {
            if (origHeight !== undefined) {
                css(parentContainer!.current!, {
                    minHeight: origHeight,
                });
            }
        };
    }, [contentRef, parentContainer]);

    return (
        <div className={styles.container}>
            <div className={styles.content} ref={contentRef}>
                <div className={styles.icon}>
                    <img src={grimace} />
                </div>
                <Title big block>
                    {t("credit.criticalError.title")}
                </Title>
                <div className={styles.subTitle}>
                    {`${t("credit.criticalError.subtitle1")} ${t(service)}. ${t("credit.criticalError.subtitle2")}`}
                </div>
                <div className={styles.subTitle}>
                    {t("credit.criticalError.note")}
                </div>
                <Button onClick={close} className={styles.button}>
                    {t("credit.criticalError.button")}
                </Button>
                <ErrorText>{message}</ErrorText>
            </div>
        </div>
    );
};