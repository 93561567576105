import { h, Fragment, FunctionalComponent, ComponentProps } from "preact";
import { useState } from "preact/hooks";
import { useTranslation } from "react-i18next";

import { Section } from "@app-modal";
import { TradeLines, Inquiries, Meter } from "@containers/credit-report";
import { MSAToolRetention } from "@overlays/retention/msa-tool";

import { Link } from "@components/Link";
import { Excerpt } from "@components/Excerpt";
import { TitleBlock } from "@components/TitleBlock";
import { Card } from "@components/Card";
import { GridItem, Grid } from "@components/Grid";
import { Text, Title } from "@components/Text";
import { NoSsr } from "@components/NoSsr";

import { useMSAToolModels } from "@msa-tool/state";

import styles from "./Score.module.scss";
import equifaxLogo from "@assets/equifax-logo.png";
// import InfoIcon from "@assets/info-icon.svg";
// import EmailIcon from "@assets/email-icon.svg";
// import PrintIcon from "@assets/print-icon.svg";

import type { MSAToolViewComponent } from "@msa-tool";
import type { AnswerMutation } from "@graphql/schema";

type CreditReport = NonNullable<AnswerMutation["creditQuery"]>["report"];

interface ICreditScore extends Omit<NonNullable<CreditReport>, "score"> {
    score: (
        NonNullable<
            NonNullable<
                NonNullable<
                    CreditReport
                >["score"]
            >["value"]
        >
    );
    likelihood: string;
}

const likelihoodMap: [number, string][] = [
    [650, "High"],
    [0, "Probable"],
];

const getLikelihood = (score: number): string => (
    (
        likelihoodMap.find(([val]) => score >= val) ||
        likelihoodMap.slice(-1)[0]!
    )[1]
);

const getCreditReport = (
    creditReport: CreditReport,
): ICreditScore | null => {
    const score = creditReport?.score?.value;
    const inquiries = creditReport?.inquiries || [];
    const trades = creditReport?.trades || [];
    if (typeof score !== "number") {
        return null;
    }

    return {
        score,
        inquiries,
        trades,
        likelihood: getLikelihood(score),
    };
};

interface ISeeMoreProps extends Pick<
    ComponentProps<MSAToolViewComponent>,
    | "navigateTo"
> {}

const SeeMore: FunctionalComponent<ISeeMoreProps> = ({ navigateTo }) => (
    <div className={styles.seeMoreContainer}>
        <Link
            label="See more"
            className={styles.seeMoreLink}
            onClick={() => navigateTo("score-details")}
        />
    </div>
);

export const CustomScore: MSAToolViewComponent = (viewProps) => {
    const {
        showError,
        navigateTo,
        goToNext,
    } = viewProps;

    const {
        msaTool: {
            useSelector,
        },
    } = useMSAToolModels();
    const { t } = useTranslation();
    const report = useSelector("report");
    const creditQuery = useSelector("query");
    const [creditReport] = useState(() => getCreditReport(report) || {} as ICreditScore);
    const noHit = (creditReport?.score || 0) <= 0;

    if (process.browser) {
        if (creditQuery?.equifaxServerError) {
            navigateTo("equifax-down");
            return null;
        }
        if (creditReport.score === undefined) {
            showError(t("credit.report.invalidScore"), {
                extra: {
                    report,
                },
            });
            return null;
        }
    }

    return (
        <Fragment>
            <TitleBlock
                title={t("msa.clcScore.title")}
                titleClass={styles.title}
            />
            <NoSsr>
                <Section grid columns={2} className={styles.gridColumns}>
                    <GridItem fullWidth>
                        <div className={styles.summary}>
                            <Text style={{ paddingBottom: "15px" }} block>
                                {t("msa.clcScore.header")}
                            </Text>
                        </div>
                    </GridItem>
                    <GridItem fullWidth>
                        <div>
                            <Meter score={creditReport.score} noHit={noHit} />
                        </div>
                    </GridItem>
                    <GridItem fullWidth>
                        <div>
                            <Text block className={styles.equifaxSubtitle}>
                                <span>
                                    {t("credit.report.equifaxSubtitle")}
                                    <img src={equifaxLogo} style={{
                                        paddingLeft: "2px",
                                        paddingRight: "2px",
                                    }} />
                                    Canada
                                </span>
                            </Text>
                            <Excerpt
                                block
                                className={styles.equifax}
                                excerpt={t("credit.report.equifaxExcerpt")}
                                complete={t("credit.report.equifaxComplete")}
                            />
                        </div>
                    </GridItem>
                    {!noHit && (
                        <>
                            <Card className={styles.scoreDetails}>
                                <Inquiries
                                    inquiries={creditReport.inquiries}
                                    numEntries={3}
                                    action={(
                                        <SeeMore navigateTo={navigateTo} />
                                    )}
                                />
                            </Card>
                            <Card className={styles.scoreDetails}>
                                <TradeLines
                                    tradeLines={creditReport.trades}
                                    numEntries={2}
                                    action={(
                                        <SeeMore navigateTo={navigateTo} />
                                    )}
                                />
                            </Card>
                        </>
                    )}
                </Section>
            </NoSsr>
            <MSAToolRetention
                {...viewProps}
                page="score"
                feedback
            />
        </Fragment>
    );
};