import { h, FunctionalComponent } from "preact";
import { useTranslation } from "react-i18next";
import { Text } from "@components/Text";
import { Spinner } from "@components/Spinner";

import styles from "./Loading.module.scss";

export const Loading: FunctionalComponent = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div className={styles.loading}>
                <Spinner />
                <Text className={styles.loading}>{t("modalLoading.text")}</Text>
            </div>
        </div>
    );
};