import { MSAToolApi } from "@msa-tool/api";
import { triggerEvent, errorHandler } from "@host";

import { ProductType } from "@graphql/schema";

import { getAsyncActionCreator } from "../creators";
import { rootSelectors } from "../selectors";
import dayjs from "dayjs";

const asyncCreator = getAsyncActionCreator(rootSelectors);

export const partialLeadActions = asyncCreator({
    async submitMSALead(product: any, models) {
        const { widgetId: dealerId, widget, } = models.dealer.state;
        const { widgetId: distId } = models.user.state.distCompany || {};
        const input = rootSelectors.getPartialPayload(null, models);
        const widgetId = distId || dealerId;

        // Extract months and years for income and residence from input
        // Calculate the current date and the difference in years and months from the provided income and residence dates
        const { incomeStartYear, residenceStartYear, residenceStartMonth, incomeStartMonth } = models.msaTool.state;

        const currentDate = dayjs();

        // Ensure the month is always treated as a string and is two digits, handling both number and string inputs
        const formatMonth = (month: unknown) => String(month).padStart(2, '0');

        // Format the month input for income and residence
        const incomeMonthPadded = formatMonth(incomeStartMonth);
        const residenceMonthPadded = formatMonth(residenceStartMonth);

        // Calculate the difference for income
        const incomeDate = dayjs(`${incomeStartYear}-${incomeMonthPadded}-01`, 'YYYY-MM-DD');
        const totalMonthsDifferenceIncome = currentDate.diff(incomeDate, 'months');
        const incomeYear = Math.floor(totalMonthsDifferenceIncome / 12);
        const incomeMonth = totalMonthsDifferenceIncome % 12;

        // Calculate the difference for residence
        const residenceDate = dayjs(`${residenceStartYear}-${residenceMonthPadded}-01`, 'YYYY-MM-DD');
        const totalMonthsDifferenceResidence = currentDate.diff(residenceDate, 'months');
        const residenceYear = Math.floor(totalMonthsDifferenceResidence / 12);
        const residenceMonth = totalMonthsDifferenceResidence % 12;

        const parsedInput = {
            ...input,
            incomeTimeYears: incomeYear,
            incomeTimeMonths: incomeMonth,
            residenceTimeYears: residenceYear,
            residenceTimeMonths: residenceMonth
        }

        try {
            const result = await MSAToolApi.submitPartial({
                widgetId,
                input: parsedInput,
                product: ProductType.CreditTool,
            });
            triggerEvent({ event: "PartialLeadSubmit" });

            if (result.submitLead?.leadId) {
                models.user.update.leadId(result.submitLead.leadId);
            }
        } catch (err) {
            throw new errorHandler.ExtendError(err, {
                widgetId,
                input,
                product: ProductType.CreditTool,
                widgetDetails: widget,
            });
        }
    },
});