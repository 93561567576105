import { h, FunctionalComponent, Fragment, VNode } from "preact";
import { useMemo } from "preact/hooks";
import { useTranslation } from "react-i18next";
import { Text, Title } from "@components/Text";

import styles from "./Inquiries.module.scss";

import type { CreditInquiries } from "../types";

interface IInquiriesProps {
    inquiries: CreditInquiries;
    /** Indicates the number of entries to display. If undefined, all entries are shown. */
    numEntries?: number;
    action?: VNode;
}

export const Inquiries: FunctionalComponent<IInquiriesProps> = ({
    inquiries,
    numEntries,
    action,
}) => {
    const { t } = useTranslation();
    const inquiryEntries = useMemo(() => (
        inquiries.slice(0, numEntries)
    ), [inquiries, numEntries]);

    return (
        <Fragment>
            <div className={styles.header}>
                <Title>{t("credit.report.inquiries.title")}</Title>
                <Text block>({t("credit.report.inquiries.subtitle")})</Text>
            </div>
            <div className={styles.entries}>
                {inquiryEntries.map((inquiry, idx) => (
                    <div className={styles.detailEntry} key={`inquiry-${idx}`}>
                        <div className={styles.detailTitle}>
                            {inquiry.inquirer?.name || t("credit.report.inquiries.unknown")}
                        </div>
                        <Text block>
                            {`${t("credit.report.inquiries.made")}: ${inquiry.date}`}
                        </Text>
                    </div>
                ))}
            </div>
            {inquiries.length > 0 && action}
        </Fragment>
    );
};